import { useEffect, useState } from "react";
import Modal from "react-modal";
import "./Home.css";

Modal.setAppElement("#root");

interface Image {
  blobURL: string;
  prompt: string;
}

const CarouselRow = (props: { carouselItems: Image[] }) => {
  return (
    <ul className="carousel-item">
      {props.carouselItems.map((image, index) => (
        <li key={index} className="span2 height2 carousel-item-content">
          <div className="image-prompt">
            <div>{image.prompt}</div>
          </div>
          <div className="image-container">
            <img
              className="image"
              key={`${index}-2`}
              src={image.blobURL}
              alt={image.prompt}
            />
          </div>
        </li>
      ))}
    </ul>
  );
};

function Home() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [imageList, setImageList] = useState<Image[]>([]);
  const [newImages, setNewImages] = useState<string[]>([]);
  const animationDuration = imageList.length * 2 + "s";

  const retryTime = 300000;
  let intervalId: string | number | NodeJS.Timeout | null = null;

  useEffect(() => {
    fetchImages();

    const connect = async () => {
      const res = await fetch(`/api/negotiate`);
      const { url } = await res.json();

      const ws = new WebSocket(url);

      ws.onopen = () => {
        console.log("Connected to SignalR");
        if (intervalId) {
          clearInterval(intervalId);
          intervalId = null;
        }
      };

      ws.onmessage = (event: any) => {
        console.log("Refreshing images...");
        setNewImages((prevImages) => [...prevImages, event.data]);
        fetchImages();
      };

      ws.onclose = () => {
        console.log("Connection closed, retrying...");
        if (!intervalId) {
          intervalId = setInterval(connect, retryTime);
        }
      };

      ws.onerror = (error) => {
        console.log("WebSocket error: ", error);
        console.log("Retrying connection...");
        if (!intervalId) {
          intervalId = setInterval(connect, retryTime);
        }
      };
    };
    console.log("Connecting to SignalR...");
    connect();
  }, []);

  useEffect(() => {
    if (isModalOpen) {
      const timer = setTimeout(() => {
        setIsModalOpen(false);
        setNewImages(newImages.slice(1)); // Elimina la imagen más antigua de la lista
        console.log("closing modal");
        console.log("Imágenes restantes ->", newImages.length);
        console.log(newImages);
      }, 5000);
      return () => {
        clearTimeout(timer);
      };
    } else {
      if (newImages.length > 0) {
        setIsModalOpen(true);
      }
    }
  }, [newImages, isModalOpen]);

  const fetchImages = async () => {
    let imagesResponse = await fetch("/api/ImagesFromAgora");

    if (!imagesResponse.ok) {
      console.error(
        `Failed to fetch images from backend. Status: ${imagesResponse.status} ${imagesResponse.statusText}`
      );
      return;
    }
    const images = await imagesResponse.json();

    setImageList(images);
  };

  return (
    <>
      <div
        className="scroll"
        style={
          { "--animation-duration": animationDuration } as React.CSSProperties
        }
      >
        <CarouselRow key="carouselrow-1" carouselItems={imageList} />
        <CarouselRow key="carouselrow-2" carouselItems={imageList} />
        <div id="origen-logo"></div>
      </div>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.7)", // Esto difuminará el fondo
          },
          content: {
            margin: "auto", // Center the modal
            width: "80vh", // Set the width to auto
            height: "80vh", // Set the height to auto
            justifyContent: "center",
            alignItems: "center",
            transition: "opacity 1s", // Add a transition
            opacity: isModalOpen ? 1 : 0, // Control the opacity with the state
          },
        }}
      >
        {newImages && (
          <img
            src={newImages[0]}
            alt="Current"
            style={{ height: "100%", width: "100%", objectFit: "cover" }}
          />
        )}
      </Modal>
    </>
  );
}

export default Home;
